<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pr4 bzbb">
            <MetaRightSideHeader>
            <template #title>组织架构</template>
            <template #bottom-tip>管理组织成员</template>
            </MetaRightSideHeader>
            <el-container style="padding: 0 20px;background: white;flex: 1;overflow-y: auto" class="shadow">

              <div class="organization-container">
                <div class="org-side-menu">
                  <el-input
                      type="text"
                      style="margin-bottom: 5px;"
                      v-model="filterText"
                      placeholder="请输入您想查找的组织名称"
                  />
                  <div class="organizationTree custom-scroller">
                    <el-tree
                        ref="orgTree"
                        :data="organizations"
                        node-key="id"
                        default-expand-all
                        :expand-on-click-node="false"
                        :current-node-key="activeOrganization.id"
                        @node-click="organizationClick">
                      <template #default="{ node, data }" >
                        <span class="custom-tree-node">
                            <TreeGroupIcon @click="clickTreeNodeLabel($event)"></TreeGroupIcon>
                          <!--                  <el-icon :size="20"><avatar /></el-icon>-->
                          <span class="organization-name truncate" :title="node.label">{{ node.label }}</span>
                          <span class="tree-node-expand" @click="clickTreeNodeLabel($event)" v-if="data.children.length"><svg-icon width="100%" height="100%" name="arrow-left"></svg-icon></span>
                          <el-dropdown trigger="click" v-if="data.id != rootOrganizationId">
                            <span class="org-menu-wrapper" style="line-height: normal;" @click.stop="showOrganizationOperateMenu">
                              <i class="el-icon-menu"></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item style="height:30px;line-height:30px" @click="append(data)">添加子部门</el-dropdown-item>
                                <el-dropdown-item style="height:30px;line-height:30px" @click="edit(node,data)">编辑</el-dropdown-item>
                                <el-dropdown-item style="height:30px;line-height:30px" @click="remove(node, data)"><span style="color:#ff0000">删除</span></el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </span>
                      </template>
                    </el-tree>
                    <div class="organizationTreeBtns">
                      <el-button @click="addOrganizationClicked" size="medium">
                        <!--                      <i class="iconfont icon-admin_new_add"></i>-->
                        <div class="new_add_container">
                          <span class="new_add"> + </span>
                        </div>
                        <span>新建部门</span>
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="userContainer">
                  <!--                <div class="organization-button-group">-->
                  <div class="userContainer-header">
                    <div class="organization-title" v-if="activeOrganization">{{ activeOrganization.label }}</div>
                    <div class="otimg"><img src="../assets/images/people-outline.png"></div>
                    <div class="organization-user-count">{{ organizationUsers.length }}</div>

                  </div>

                  <div class="userContainer-operates">
                    <div class="operate-buttons">
                      <el-button @click="removeUserDrawerVisible=true" :disabled="checkedList.length !== 1" v-if="rootOrganizationId==activeOrganization.id" class="operate-button" type="danger" plain>
                        移除团队
                      </el-button>
                      <el-button @click="deleteUserFromOrganization" :disabled="checkedList.length===0" v-else class="operate-button" type="primary">
                        移除成员
                      </el-button>
                      <el-button :disabled="rootOrganizationId==activeOrganization.id" class="operate-button"
                                 @click="addOrganizationUserClicked" type="primary">
                        <span style="float: right">
  <!--                        <i class="iconfont icon-admin_new_add"></i>-->
                          <div class="new_add_container">
                            <span class="new_add"> + </span>
                          </div>
                          <span>添加成员</span>
                        </span>
                      </el-button>
                      <el-button type="primary" class="operate-button" @click="inviteUser" v-if="!config.noModel">
                        邀请成员
                      </el-button>
                    </div>
                  </div>
                  <!--                </div>-->
                  <MetaListTable
                      :table-data="organizationUsers"
                      :show-checkbox="true"
                      :table-height="'100%'"
                  >
                    <template #header>
                      <div class="action">
                        <div class="selector-wrap">
                          <MetaListSelector
                              :list="organizationUsers"
                              :show-operation-action-btn="false"
                          ></MetaListSelector>
                        </div>
                      </div>
                    </template>
                    <el-table-column
                        prop="userName"
                        label="姓名"
                        min-width="150"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="email"
                        label="邮箱"
                        min-width="150"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="phone"
                        label="手机"
                        min-width="150"
                        sortable
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        label="部门"
                        min-width="150"
                        align="center"
                        show-overflow-tooltip
                    >
                      {{
                        activeOrganization.label
                      }}
                    </el-table-column>
                  </MetaListTable>
                  <!--          <div v-if="system == 1" class="nextStep"><el-button type="primary" @click="nextStep">下一步</el-button></div>-->
                </div>
              </div>
              <el-dialog
                  :title="editOrganizationName?'编辑部门':'添加部门'"
                  v-model="addOrganizationDialogVisible"
                  width="30%"
                  center>
                <el-input
                    type="text"
                    placeholder="请输入部门名称"
                    v-model="newOrganizationName"
                    maxlength="15"
                    show-word-limit>
                </el-input>
                <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="addOrganizationDialogVisible = false">取 消</el-button>
                      <el-button type="primary" @click="addOrganizationSubmit">确 定</el-button>
                    </span>
                </template>
              </el-dialog>
              <el-dialog
                  title="添加成员"
                  v-if="organizationAddUserDialogVisible"
                  v-model="organizationAddUserDialogVisible"
                  @opened="organizationAddUserDialogOpened"
                  center>
                <div style="height:50vh">
                  <organizationUserSelection
                      ref="organization-user-selection"
                      select_organization="false"
                      :tool_title="'成员'"
                      :selected_users="currentOrganizationUidList">
                  </organizationUserSelection>
                </div>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="organizationAddUserDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="organizationAddUserSubmit">确 定</el-button>
                  </span>
                </template>
              </el-dialog>
              <el-drawer v-model="removeUserDrawerVisible" title="操作移除">
                  <div class="removeUserHeadImg">
                    <user-icon style="flex-shrink: 0;" size="42" :url="checkedList[0].headImgUrl" :name="checkedList[0].userName" :uid="checkedList[0].userName"></user-icon>
                    <span style="font-size: 16px;padding-left: 20px;padding-right: 15px;">{{checkedList[0].userName}}</span>
                  </div>
                  <div style="margin-top: 50px;margin-left: 30px;margin-right: 30px">
                      <div>
                          <span style="font-size: 15px">设置接收人</span>
                      </div>
                      <div style="margin-top: 25px">
                          <el-table
                             :data="transferTable"
                             style="width: 100%"
                             :border="true"
                          >
                            <el-table-column
                               prop="resource"
                               label="资源"
                               width="100"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="describe"
                                label="描述">
                            </el-table-column>
                            <el-table-column
                                label="接收人"
                                width="160"
                            >
                                <el-select v-model="recipientUid" filterable clearable placeholder="查找团队成员">
                                    <el-option
                                        v-for="user in teamUsers"
                                        :key="user.uid"
                                        :label="user.userName"
                                        :value="user.uid"
                                        :disabled="user.uid==checkedList[0].uid"
                                    />
                                </el-select>
                            </el-table-column>
                          </el-table>
                      </div>
                  </div>
                  <div style="display: inline-flex;margin-left: 30px;position: fixed;bottom: 25px">
                      <el-button type="danger" style="border-radius: 5px !important;" @click="secondConfirmationVisible=true" :disabled="recipientUid==''">确认移除</el-button>
                      <el-button style="border-radius: 5px !important;" @click="removeUserDrawerVisible=false,recipientUid=''">取消</el-button>
                  </div>
              </el-drawer>
              <el-dialog v-model="secondConfirmationVisible"
                         title="移除成员"
                         :center="true"
                         width="410px"
                         top="250px"
              >
                  <div style="display: flex;flex-direction: row;justify-content: center;">
                      <img src="../assets/images/error.png" style="height: 15px;width: 15px;margin-top: 2px">
                      <div style="display: flex;flex-direction: column;align-items: flex-start;margin-left: 5px">
                          <span style="letter-spacing: 0.6px">确认要将<strong>{{checkedList[0].userName}}</strong>的账号移除团队吗?</span>
                          <span style="letter-spacing: 0.6px">移除后,该账号将无法使用MetaGo访问本团队</span>
                          <div style="padding-top: 20px;display: inline-flex;justify-content: right;width: 100%">
                              <el-button size="small" style="border-radius: 5px !important;" @click="secondConfirmationVisible=false">取消</el-button>
                              <el-button size="small" type="danger" style="border-radius: 5px !important;" @click="removeUser">确认删除</el-button>
                          </div>
                      </div>
                  </div>
              </el-dialog>
            </el-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <invite-user ref="invite" :teamId="userInfo.mainSiteTeamId" :activeOrganization="activeOrganization"></invite-user>
</template>

<script>
import {
  getTeamOrganizations,
  saveOrganization,
  deleteOrganization,
  getOrganizationUser,
  organizationSaveUser,
  downloadOrganizationInviteMemberQRCode,
  updateOrganization,
  getTeamUser,
  removeUser
} from "../api/api";
import organizationUserSelection from "../components/organizationUserSelection";
import {debounce, MetaMessage} from "../util";
import {ElLoading} from "element-plus";
import config from "../config";
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import MetaListTable from "../components/list/MetaListTable";
import MetaListSelector from "../components/list/MetaListSelector";
import InviteUser from "../components/InviteUser";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {BehaviorPermission, checkUserBehaviorPermission} from "../components/common/permissionUtil";
import TreeGroupIcon from "../components/universalUI/TreeGroupIcon";
import {globalTagClick} from "../assets/js/tag/tag";
import UserIcon from "../components/UserIcon";
import {ElMessage,ElMessageBox} from 'element-plus';

export default {
  name: "organization",
  data: function () {
    return {
      config:config,
      system: this.$route.query.system || 0,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      teamInfo:JSON.parse(sessionStorage.getItem("teamInfo")),
      teamId: '',
      metaMessage: new MetaMessage(),
      activeName: "user",
      addOrganizationDialogVisible: false,
      newOrganizationName: "",
      pertinentOrganizationData: null,
      organizations: [],
      activeOrganization: {id: "",label:""},
      organizationUsers: [],
      tempOrganizationUsers: [],
      originFormattedOrganizations:[],
      organizationAddUserDialogVisible: false,
      currentOrganizationUidList: [],
      loadingInstance: null,
      organizationInviteOuterUserDialogVisible: false,
      inviteUrlPrefix: '我和“meta”的小伙伴都在MetaGo等你，用这个专属链接加入我们吧！',
      inviteUrl: '',
      filterText:'',
      rootOrganizationId: '',
      editOrganizationName:false,
      tempEditNode:'',
      debounceTagClickFun:debounce((text)=>{
        globalTagClick('organization-search',{text});
      },500),
      checkedList: [],
      removeUserDrawerVisible:false,
      transferTable:[
          {
            resource:"审批流程",
            describe: "转让此账号担任审批人的审批流程",
          },
      ],
      teamUsers:[],
      recipientUid:'',
      secondConfirmationVisible: false,
    }
  },
  mounted() {
    console.log('this.system === ', this.system);
    if (!this.userInfo || !checkUserBehaviorPermission(this.userInfo.mainSiteTeamId,BehaviorPermission.MANAGE_ORGANIZATION)) {
      this.$router.push({
        name: 'contractIndex'
      })
      return;
    }
    this.teamId = this.userInfo.mainSiteTeamId;
    getTeamOrganizations(this.teamId).then(res => {
      if (this.rootOrganizationId == '') this.rootOrganizationId = res.data.data[0].id;
      if (res.data.code === 0) {
        this.renderOrganizations(res.data.data.filter(organization => organization.visible==true));
      }
    }).catch(error => {
      console.error('get organizations  error === ', error);
    });
    getTeamUser(this.teamId).then(res => {
        if (res.data.code === 0){
            this.teamUsers = res.data.data;
            console.log("teamUsers => ",this.teamUsers)
        }else {
            ElMessage.error("加载团队成员失败")
        }
    }).catch(error => {
        ElMessage.error("加载团队成员失败")
        console.error('getTeamUser  error === ', error);
    });
  },
  watch:{
    // 过滤操作
    filterText(val) {
      this.filterOrganizations(JSON.parse(JSON.stringify(this.originFormattedOrganizations)),val);
      this.debounceTagClickFun(val);
    },
    'organizationUsers':{
      handler:function (){
        this.checkedList=this.organizationUsers.filter((item)=>item.checked);
      },
      deep:true
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    filterOrganizations(orgs,keyword){
      this.organizations = [];

      const find = (orgs) => {
        orgs.forEach(org=>{
          if(org.label.indexOf(keyword) >= 0){
            this.organizations.push(org);
          }else if(org.children && org.children.length > 0){
            find(org.children);
          }
        })
      }
      find(orgs);
    },
    renderOrganizations: function (orgs) {
      this.organizations = [];
      for (const org of orgs) {
        this.pushOrganization(org, this.organizations);
      }
      this.activeOrganization = this.organizations[0];
      this.getOrganizationUsers(this.activeOrganization.id);
      // this.$refs.orgTree.setCurrentKey(this.organizations[1].id);
      // this.$refs.orgTree.setCurrentNode(this.organizations[1]);
      this.originFormattedOrganizations = JSON.parse(JSON.stringify(this.organizations));
    },
    pushOrganization: function (org, target) {
      if (org.parentId) {
        for (const t of target) {
          if (t.id === org.parentId) {
            t.children.push({
              id: org.id,
              label: org.name,
              children: []
            });
          } else {
            this.pushOrganization(org, t.children);
          }
        }
      } else {
        target.push({
          id: org.id,
          label: org.name,
          children: []
        });
      }
    },
    addOrganizationClicked: function () {
      this.editOrganizationName = false;
      this.addOrganizationDialogVisible = true;
      this.pertinentOrganizationData = null;
    },
    addOrganizationSubmit: function () {
      if(this.editOrganizationName) {
        globalTagClick('organization-edit-organization');
      }else{
        globalTagClick('organization-add-organization');
      }

      if (this.newOrganizationName === "" || !/\S/g.test(this.newOrganizationName)) {
        this.metaMessage['error']("请输入部门名称");
        return;
      }
      const postData = {name: this.newOrganizationName, teamId: this.teamId};
      if (this.pertinentOrganizationData != null) {
        postData['parentId'] = this.pertinentOrganizationData.id;
      }else{
        postData['parentId'] = this.rootOrganizationId;
      }
      if(!this.editOrganizationName){
      this.openLoading();
        saveOrganization(postData).then(res => {
          console.log("addOrganizationSubmit  res ==== ", res);
          if (res.data.code === 0) {
            if (this.pertinentOrganizationData == null) {
              // 新增一级组织名称
              this.organizations[0].children.push({
                id: res.data.data.id,
                label: res.data.data.name,
                children: []
              })
            } else {
                // 新增子部门
              const newChild = {id: res.data.data.id, label: res.data.data.name, children: []};
              if (!this.pertinentOrganizationData.children) {
                this.$set(this.pertinentOrganizationData, 'children', []);
              }
              this.pertinentOrganizationData.children.push(newChild);
            }
          } else {
            this.metaMessage['error']("添加部门失败 " + res.data.message ? res.data.message : "");
          }
          this.addOrganizationDialogVisible = false;
          this.closeLoading();
          this.metaMessage['info']('添加成功');
        }).catch(err => {
          console.log("addOrganizationSubmit  err ==== ", err);
          this.closeLoading();
          this.metaMessage['error']("添加部门失败");
        })
      }else{
        if (this.newOrganizationName === this.pertinentOrganizationData.label){
          this.addOrganizationDialogVisible = false;
          return;
        }
        this.openLoading();
        updateOrganization(this.pertinentOrganizationData.id,this.newOrganizationName).then(res => {
          this.tempEditNode.data.label =  res.data.data.name;
          this.editOrganizationName = false;
          this.addOrganizationDialogVisible = false;
          this.closeLoading();
          this.metaMessage['info']('修改成功');
        }).catch(err => {
          console.log("updateOrganizationSubmit  err ==== ", err);
          // this.editOrganizationName = false;
          this.metaMessage['error']("修改部门名称失败");
          this.closeLoading();
        })
      }
    },
    showOrganizationOperateMenu: function () {
      console.log('showOrganizationOperateMenu ---------------')
    },
    append(data) {
      console.log('append   data ==== ', data);
      this.editOrganizationName = false;
      this.pertinentOrganizationData = data;
      this.addOrganizationDialogVisible = true;
    },
    remove(node, data) {
      this.openLoading();
      getOrganizationUser(data.id).then(result =>{
        this.tempOrganizationUsers = result.data.data;
        if(this.tempOrganizationUsers.length != 0){
          this.metaMessage['warning']('请先将当前部门成员清空');
          this.closeLoading();
        }
        else if(node.data.children.length != 0){
          this.metaMessage['warning']('请先删除子部门');
          this.closeLoading();
        }else{
          globalTagClick('organization-delete-organization',data);
          this.deleteOrganization(node,data)
        }
      }).catch(err => {
          console.error("delete organization err:", err);
          this.closeLoading();
          this.metaMessage['error']("部门人员校验失败");
      })
    },
    deleteOrganization:function(node,data){
      deleteOrganization(data.id).then(res => {
        if (res.data.code === 0) {
          const parent = node.parent;
          const children = parent.data.children || parent.data;
          const index = children.findIndex(d => d.id === data.id);
          children.splice(index, 1);
          this.closeLoading();
          this.metaMessage['info']('删除成功');
        } else {
          this.closeLoading();
          this.metaMessage['error']("删除部门失败");
        }
      }).catch(err => {
        console.error("delete organization err:", err);
        this.closeLoading();
        this.metaMessage['error']("删除部门失败");
      })
    },
    edit:function(node,data){
      this.editOrganizationName = true;
      this.tempEditNode = node;
      this.newOrganizationName = data.label;
      this.pertinentOrganizationData = data;
      this.addOrganizationDialogVisible = true;
    },
    deleteUserFromOrganization : function(){
      const checkedUids = this.checkedList.map((user) => {
          return user.uid;
      });
      const userList = this.organizationUsers.filter((user) =>{
          return $.inArray(user.uid,checkedUids)==-1;
      });
      const uidList = userList.map((user) => {
         return user.uid;
      });
      organizationSaveUser(this.activeOrganization.id, uidList).then(res => {
        if (res.data.code == 0){
          this.organizationUsers.forEach((item)=>item.checked=false);
          globalTagClick('organization-delete-member',{organizationId:this.activeOrganization.id,uidList});
          this.organizationUsers = userList;
          this.metaMessage['success']('移除成员成功');
        }
        else{
          this.metaMessage['error'](res.data.msg);
        }
      }).catch(err =>{
        console.error('移除成员失败  err:', err);
        this.metaMessage['error']('移除成员失败');
      });

    },
    organizationClick: function (data) {
      this.organizationUsers = [];
      console.log('organizationClick   data === ', data);
      this.activeOrganization = data;
      // 查询该组织机构下的人员
      this.getOrganizationUsers(data.id);
    },
    getOrganizationUsers: function (organizationId) {
      getOrganizationUser(organizationId).then(res => {
        console.log('getOrganizationUsers  res === ', res);
        this.organizationUsers = res.data.data;
      }).catch(err => {
        console.error('getOrganizationUsers  err === ', err);
        this.metaMessage['error']("获取部门成员失败");
      })
    },
    addOrganizationUserClicked: function () {
      console.log('this.organizationUsers ========== ', this.organizationUsers);
      this.currentOrganizationUidList = this.organizationUsers.map((organizationUser) => {
        return organizationUser.uid;
      });
      this.organizationAddUserDialogVisible = true;
    },
    organizationAddUserDialogOpened: function () {
      this.$refs['organization-user-selection'].setSelectedObjects();
    },
    organizationAddUserSubmit: function () {
      const selectObjects = this.$refs['organization-user-selection'].getSelectedObjects();
      console.log('organizationAddUserSubmit  selectObjects === ', selectObjects);
      const uidList = selectObjects.map((o) => {
        return o.objectId;
      })
      globalTagClick('organization-add-member',{'organizationId':this.activeOrganization.id,uidList});
      this.openLoading();
      organizationSaveUser(this.activeOrganization.id, uidList).then(res => {
        console.log('organizationSaveMembers  res === ', res);
        this.closeLoading();
        if (res.data.code === 0) {
          this.organizationUsers = res.data.data;
          this.metaMessage['info']('添加成员成功');
        } else if(res.data.code === 406){
          //无效请求兜底if
        }
        else {
          this.metaMessage['error'](res.data.msg);
        }
        this.organizationAddUserDialogVisible = false;
      }).catch(err => {
        console.error('添加成员失败  err:', err);
        this.closeLoading();
        this.metaMessage['error']('添加成员失败');
      })
    },
    inviteButtonClicked: function () {
      console.log('inviteButtonClicked  this.activeOrganization === ', this.activeOrganization);
      this.inviteUrl = this.inviteUrlPrefix + config.baseUrl + 'profile/#/contract/home?vo=' + this.activeOrganization.id;
      console.log('inviteUrl ==== ', this.inviteUrl);
      this.organizationInviteOuterUserDialogVisible = true;
    },
    organizationInviteOuterUserDialogOpened: function () {
      this.$refs["organization-invite-user"].initQRCode();
      // getOrganizationInviteMemberQRCode(this.activeOrganization.id).then(res => {
      //   console.log('获取组织邀请二维码  res:', res);
      //   document.getElementById("inviteMemberQRCodeImg").setAttribute('src', 'data:image/png;base64,' + res.data.data);
      // }).catch(err => {
      //   console.error('获取组织邀请二维码失败  err:', err);
      //   this.metaMessage['error']('获取组织邀请二维码失败');
      // })
    },
    copyInviteUrlButtonClicked: function () {
      let copyTest = config.baseUrl + 'profile/#/contract/home?vo=' + this.activeOrganization.id;
      let inputTest = document.createElement('input');
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand('Copy');
      inputTest.className = 'oInput';
      inputTest.style.display = 'none';
      inputTest.remove();
      this.metaMessage['info']('复制成功');
    },
    downloadInviteQRCodeButtonClicked: function () {
      this.openLoading();
      downloadOrganizationInviteMemberQRCode(this.activeOrganization.id).then(res => {
        console.log('downloadOrganizationInviteMemberQRCode  res === ', res)
        this.closeLoading();
        let url = window.URL.createObjectURL(res.data);
        let a = document.createElement('a');
        let filename = decodeURIComponent(res.headers['filename']);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(err => {
        console.error('downloadOrganizationInviteMemberQRCode  err:', err);
        this.closeLoading();
        this.metaMessage['error']('下载二维码失败');
      })
    },
    openLoading: function() {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: 'el-icon-loading'
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    },
    nextStep: function () {
      this.$router.push({
        name: 'contractHome',
      })
    },
    clickTreeNodeLabel(e){
      e.target.closest('.el-tree-node__content').querySelector('.el-tree-node__expand-icon').click();
    },
    inviteUser: function () {
      globalTagClick('organization-invite-usr');
      this.$refs.invite.inviteUser();
    },
    removeUser(){
      removeUser(this.teamId,this.checkedList[0].uid,this.recipientUid).then(res => {
          this.removeUserDrawerVisible=false;
          this.recipientUid='';
          this.secondConfirmationVisible=false;
          //organizationUserList删除
          if (res.data.code === 0){
              location.reload();
              ElMessage({
                  type: 'success',
                  message: '删除成功',
              })
          }else{
              ElMessage({
                  type: 'error',
                  message: res.data.msg,
              })
          }
      }).catch(e => {
          ElMessage({
              type: 'error',
              message: '删除失败',
          })
          console.log("removeUser error =>",e)
      });
    }
  },
  components: {
    UserIcon,
    TreeGroupIcon,
    MetaRightSideHeader,
    ContractSettingLeftMenu, organizationUserSelection, InviteUser,MetaListTable,MetaListSelector }
}
</script>

<style scoped>
.organization-button-group{
  display:flex;
  justify-content: right;
}
.organizationTree {
  height: calc(100% - 40px);
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
}

.organizationTree >>> .el-tree-node__content{
  height: 31px;
}
.organizationTree >>> .el-tree-node__expand-icon{
  display: none;
}

.organizationTreeBtns {
  margin-top: 10px;
}

.organization-title {
  display: inline-block;
}

.organization-user-count {
  display: inline-block;
  margin-left: 5px;
}

.userContainer-operates {
  margin: 10px 0;
  text-align: right;
  /*display: flex;*/
  justify-content: space-between;
}

.organization-container {
  text-align: left;
  flex-direction: row;
  display: flex;
  padding: 10px 0;
  /*height: calc(100% - 40px);*/
  width: 100%;
  /*position: absolute;*/
  --org-side-menu-width:260px;
}

.operate-button {
  margin-left: 10px;
}
.operate-buttons{
  display: flex;
  flex-direction: row-reverse;
}

.tree-node-expand{
  display: inline-block;
  width: 14px;
  height: 14px;
  padding:5px;
  transition: .5s transform,.5s color;
  transform: rotate(-180deg);
}
.tree-node-expand:hover{
  color: #5280FF;
}
.tree-node-expand .svg-icon{
  vertical-align: top;
}

.custom-tree-node {
  display: flex;
  height: var(--userIconSize);
  width: 100%;
  align-items: center;
  position: relative;
  padding-right: 5px;
  box-sizing: border-box;
}


.organization-name {
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  box-sizing: border-box;
}

.org-menu-icon {
  min-width: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  color: rgb(43, 47, 54);
  cursor: pointer;
}

.org-menu-icon img {
  width: 20px;
  height: 20px;
}

.fnncnvw {
  /*position: absolute;*/
  right: 150px;
  display: inline-flex;
  height: 36px;
  line-height: 36px;
  align-items: center;
}

.userContainer {
  position: relative;
  flex: 1;
  /*height: calc(100% - 40px);*/
  padding: 0 20px;
  transform-origin: right;
  border-left: none;
  /*left: 280px;*/
  width: calc(100% - var(--org-side-menu-width));
  display: flex;
  flex-direction: column;

}

.userContainer-header {
  height: 36px;
  line-height: 36px;
}
.org-side-menu {
  position: relative;
  width: var(--org-side-menu-width);
  height: 100%;
  flex: 0 0 var(--org-side-menu-width);
  min-width: 0;
}

.otimg {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.otimg img {
  width: 16px;
  height: 16px;
}

.nextStep {
  margin-top: 50px;
  text-align: center;
}
.new_add_container{
  display: inline-block;
  padding-right: 6px;
  font-size: 20px;
  line-height: 13px;
}
.organizationTree .el-tree-node__expand-icon.expanded~.custom-tree-node .tree-node-expand{
  transform: rotate(-90deg);
}
.el-tree-node__content>label.el-checkbox{
  margin-right: 10px;
}
/deep/ #el-drawer__title span{
  font-size: 16px;
  color: #101010;
  text-align: center;
}
.removeUserHeadImg{
  margin-left: 30px;
  margin-top: 20px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  background-color: #D6E1FF
}
</style>
<style>
/*.el-dropdown {*/
/*  position: absolute;*/
/*  right: 10px;*/
/*}*/
/*.el-tree-node__content {*/
/*  padding: 3px 10px 3px 54px;*/
/*}*/
</style>
